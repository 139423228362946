<template>
  <div class="vg_wrapper">
    <el-card>
      <publicTips></publicTips>
      <div class="vd_search_group">
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getMtrlsNow()" label-width="120px">
          <el-row>
            <el-col :md="8">
              <el-form-item label="物料编号:">
                <el-input size="small" v-model.trim="searchForm.mtrl_no" clearable placeholder="请填写物料编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料名称:">
                <el-input size="small" v-model.trim="searchForm.mtrl_name" clearable placeholder="请填写物料名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料英文名称:">
                <el-input size="small" v-model.trim="searchForm.mtrl_ename" clearable placeholder="请填写物料英文名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料规格:">
                <el-input size="small" v-model.trim="searchForm.mtrl_spec" clearable placeholder="请填写物料规格"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="潘通色号:">
                <el-input size="small" v-model.trim="searchForm.mtrl_color" clearable placeholder="请填写潘通色号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料克重:">
                <el-input size="small" v-model.trim="searchForm.mtrl_weight" clearable placeholder="请填写克重"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料类型:">
                <el-select size="small" v-model="searchForm.mtrl_type" placeholder="请选择物料类型" clearable>
                  <el-option v-for="item in mtrlType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- @input="
                goodsForm.cost_price =
                  goodsForm.cost_price
                    .replace(/^0+(\d)/, '$1')
                    .replace(/^\./, '0.')
                    .match(/^\d*(\.?\d{0,2})/g)[0] || ''
              " -->
            <el-col :md="8">
              <el-form-item label="物料门幅:">
                <el-input
                  size="small"
                  v-model.trim="searchForm.mtrl_width"
                  @input="
                    searchForm.mtrl_width =
                      searchForm.mtrl_width
                        .replace(/^0+(\d)/, '$1')
                        .replace(/^\./, '0.')
                        .match(/^\d*(\.?\d{0,4})/g)[0] || ''
                  "
                  @change="searchForm.mtrl_width = helper.reservedDigits4(searchForm.mtrl_width)"
                  clearable
                  placeholder="请填写物料门幅"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="颜色大类:">
                <el-select size="small" v-model="searchForm.colr_class" placeholder="请选择颜色大类" clearable multiple filterable>
                  <el-option v-for="item in optnColorList" :key="item.param1" :label="item.param1" :value="item.param1"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="供应商:">
                <el-select size="small" v-model="searchForm.supp_id" placeholder="请选择供应商" clearable filterable>
                  <el-option v-for="item in suppList" :key="item.supp_id" :label="item.supp_name" :value="item.supp_id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="单据状态:">
                <el-select size="small" v-model="searchForm.status" placeholder="请选择单据状态" clearable>
                  <el-option v-for="item in statusOption" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料毛高:">
                <el-input
                  size="small"
                  v-model.trim="searchForm.mtrl_thick"
                  @input="searchForm.mtrl_thick = helper.keepEngNum4(searchForm.mtrl_thick)"
                  clearable
                  placeholder="请填写物料毛高"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="打样部门:">
                <el-select size="small" v-model="searchForm.dept_id" placeholder="请选择打样部门" clearable>
                  <el-option v-for="item in deptTypeList" :key="item.id" :label="item.param1" :value="item.param2"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料成分:">
                <el-select v-model="searchForm.mtrl_comp" multiple placeholder="请选择物料成分" size="small" clearable filterable>
                  <el-option v-for="item in compType" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="录入人:">
                <el-select v-model="searchForm.stff_name" placeholder="请选择录入人" size="small" clearable filterable>
                  <el-option v-for="(item, index) in stffType" :key="index" :label="item.stff_name" :value="item.stff_name"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="16">
              <el-form-item label="录入时间范围:">
                <div class="block vg_mr_32">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    :picker-options="pickerOptions"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getMtrlsNow()" class="vg_ml_16">查询</el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"
          ><i class="el-icon-delete" :disabled="!btn.delete"></i> 删除</el-button
        >
        <el-button type="primary" plain size="small" @click="oneClick()">一键改价</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            class="vg_cursor"
            @sort-change="sortChange"
            ref="multiTable"
            :data="tableData"
            @selection-change="handleSelectionChange"
            @select="selectRows"
            @select-all="selectRows"
            @row-dblclick="dbClickJp"
            border
            v-loading="loadFlag"
          >
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column label="物料编号" sortable prop="mtrl_no" />
            <el-table-column label="物料图片" align="center" v-if="!loadFlag">
              <template slot-scope="scope">
                <el-popover v-if="scope.row.imge_url" placement="right" width="400" trigger="hover">
                  <div>
                    <el-image style="width: 100%" class="vd_popimg" :src="formatPic(scope, 'l')" fit="fill"> </el-image>
                  </div>
                  <el-image slot="reference" class="vd_elimg" :src="formatPic(scope, 's')" fit="fill"> </el-image>
                </el-popover>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料名称" prop="mtrl_name" show-overflow-tooltip />
            <el-table-column label="物料英文名称" prop="mtrl_spec_en" show-overflow-tooltip />
            <el-table-column label="供应商" prop="supp_name" show-overflow-tooltip />
            <el-table-column label="物料规格" prop="mtrl_spec" show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_spec">
                  {{ scope.row.mtrl_spec }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料类型">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_type >= 0">
                  {{ scope.row.mtrl_type | formatLeavType }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料成分" prop="mtrl_comp">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_comp">
                  {{ scope.row.mtrl_comp }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料单位" prop="mtrl_unit">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_unit">
                  {{ scope.row.mtrl_unit }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="潘通色号" prop="mtrl_color" width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_color">
                  <div class="vd_dis">
                    <div :class="{ vd_div: scope.row.colr_html }" :style="{ 'background-color': scope.row.colr_html }"></div>
                    <span>{{ scope.row.mtrl_color }}</span>
                  </div>
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column prop="colr_class" label="颜色大类" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.colr_class">
                  {{ scope.row.colr_class }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料门幅" prop="mtrl_width">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_width">
                  {{ scope.row.mtrl_width | formaUnitM }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="克重" prop="mtrl_weight">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_weight">
                  {{ scope.row.mtrl_weight }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料毛高" sortable prop="mtrl_thick">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_thick">
                  {{ scope.row.mtrl_thick | formaUnitH }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料单价" prop="mtrl_prod_price" show-overflow-tooltip :formatter="formaUnitP"> </el-table-column>
            <el-table-column label="打样部门" prop="mtrl_dept_stock_strs" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.mtrl_dept_stock_strs.length > 0">
                  <el-tag v-for="item in scope.row.mtrl_dept_stock_strs" :key="item">{{ item }}</el-tag>
                </div>
                <span v-else class="vg_9f9a9a">暂无库存</span>
              </template>
            </el-table-column>
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina" />
            <el-table-column label="创建时间" prop="create_time" :formatter="formatDate" show-overflow-tooltip />
            <el-table-column label="单据状态" :show-overflow-tooltip="true" align="center">
              <template slot-scope="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">{{
                  helper.getStatusName(scope.row.status).name
                }}</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-dialog :visible.sync="splitFlag" width="30%">
        <el-row class="vg_mb_16 vd_dis">
          <el-col :md="6" class="vd_right"> 修改单价 </el-col>
          <!-- </el-row>
        <el-row class="vg_mb_16"> -->
          <el-col :md="18">
            <el-input
              v-model="inputValue"
              @input="inputValue = helper.keepTNum1(inputValue)"
              @blur="inputValue = helper.calcPrice(inputValue, 7, 1000000)"
              placeholder="请输入修改单价"
            ></el-input>
          </el-col>
        </el-row>
        <el-row class="vg_mb_16">
          <el-col :md="24" class="vg_dis_center vd_right">
            <el-button type="primary" size="small" @click="confirmPrice">确认</el-button>
            <el-button
              type="primary"
              size="small"
              plain
              @click="
                splitFlag = false;
                inputValue = '';
              "
              >取消</el-button
            >
          </el-col>
        </el-row>
      </el-dialog>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mtrlAPI } from '@api/modules/mtrl';
import { optnAPI } from '@api/modules/optn';
import { suppAPI } from '@api/modules/supp';
import pubPagination from '@/components/common/pubPagination';
import publicTips from '@/views/component/publicTips';
import helper from '@assets/js/helper.js';
import { userAPI } from '@api/modules/user';

export default {
  name: 'MtrlList',
  components: {
    pubPagination,
    publicTips
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        mtrl_ename: null,
        mtrl_no: null,
        mtrl_name: null,
        mtrl_type: null,
        mtrl_spec: null,
        mtrl_color: null,
        supp_id: null,
        mtrl_weight: null,
        colr_class: [],
        mtrl_comp: [],
        mtrl_width: null,
        stff_name: null,
        status: null,
        dept_id: null,
        mtrl_sort: null,
        mtrl_field: null,
        mtrl_thick: null,
        timeValue: {
          startTime: null,
          endTime: null
        }
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
        ]
      },
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      mtrlType: [
        { id: 0, label: '原面料' },
        { id: 1, label: '辅料' },
        { id: 2, label: '包材' }
      ],
      // 0=草拟 1=在批 2=生效
      statusOption: [
        { id: 0, label: '草拟' },
        { id: 1, label: '在批' },
        { id: 2, label: '生效' }
      ],
      imgNum: parseInt(Math.random() * 10000000000000),
      optnColorList: [],
      deptTypeList: [],
      compType: [],
      suppList: [],
      splitFlag: false,
      inputValue: '',
      stffType:[]
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/mtrl_add' || from.path === '/mtrl_edit') {
        this.initData();
      }
    }
  },
  filters: {
    // 物料类型
    formatLeavType(row) {
      if (row === 0) {
        return '原面料';
      } else if (row === 1) {
        return '辅料';
      } else if (row === 2) {
        return '包材';
      }
    },
    formaUnitM(row) {
      return helper.haveFour(row) + '米';
    },
    formaUnitH(row) {
      return helper.reservedDigits(row) + '毫米';
    }
  },
  methods: {
    initData() {
      this.getMtrlsList();
      this.getOptnColor();
      this.getOptnByPermId();
      this.getCompType();
      this.getSupp();
      this.getStffType();
    },
    // 获取物料信息
    getMtrlsList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      let colr_class = null;
      if (this.searchForm.colr_class.length > 0) {
        colr_class = this.searchForm.colr_class.join(',');
      }
      get(mtrlAPI.getMtrls, {
        mtrl_field: this.searchForm.mtrl_field,
        mtrl_sort: this.searchForm.mtrl_sort,
        mtrl_no: this.searchForm.mtrl_no,
        dept_id: this.searchForm.dept_id,
        supp_id: this.searchForm.supp_id,
        mtrl_name: this.searchForm.mtrl_name,
        mtrl_ename: this.searchForm.mtrl_ename,
        mtrl_weight: this.searchForm.mtrl_weight,
        mtrl_type: this.searchForm.mtrl_type,
        mtrl_spec: this.searchForm.mtrl_spec,
        mtrl_color: this.searchForm.mtrl_color,
        mtrl_comp: this.searchForm.mtrl_comp.join(','),
        colr_class,
        mtrl_thick: this.searchForm.mtrl_thick,
        start_time: timeNewVal.startTime ? parseInt(timeNewVal.startTime) : null,
        end_time: timeNewVal.endTime ? parseInt(timeNewVal.endTime) : null ,
        page_no: this.currentPage,
        mtrl_width: this.searchForm.mtrl_width,
        status: this.searchForm.status,
        stff_name: this.searchForm.stff_name
      })
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            // for(let i=0;i<this.tableData.length;i++){
            //   this.loadFlag = true
            //   let updateTime = JSON.parse(JSON.stringify(this.imgNum))+1
            //   if(this.tableData[i].imge_url){
            //     this.$nextTick(()=>{
            //       this.tableData[i].imge_url = this.helper.picUrl(this.tableData[i].imge_url,'s',updateTime)
            //     })

            //   }
            // }
            // console.log('12313',this.imgNum,this.tableData);
            setTimeout(() => {
              this.$nextTick(() => {
                this.loadFlag = false;
              });
            }, 1000);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    getOptnByPermId() {
      get(optnAPI.getOptnByPermId, { perm_id: 10009 })
        .then(res => {
          if (res.data.code === 0) {
            this.deptTypeList = res.data.data.form.optn_cntt_list;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    getSupp() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        mtrl_no: null,
        dept_id: null,
        mtrl_name: null,
        mtrl_ename: null,
        mtrl_weight: null,
        mtrl_type: null,
        status: null,
        mtrl_color: null,
        supp_id: null,
        mtrl_thick: null,
        mtrl_comp: [],
        colr_class: [],
        timeValue: {
          startTime: null,
          endTime: null
        }
      };
      this.loadFlag = true;
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 一键改价
    oneClick() {
      let searchNum = 0;
      if (this.searchForm.supp_id) searchNum++;
      if (this.searchForm.mtrl_weight) searchNum++;
      if (this.searchForm.mtrl_thick) searchNum++;
      if (this.searchForm.mtrl_width) searchNum++;
      if (this.searchForm.mtrl_name) searchNum++;
      if (searchNum >= 3) {
        this.getMtrlsList();
        // if(this.multiSelection.length>0){
        this.splitFlag = true;
        // }else{
        //   this.$message.warning('至少选择一条数据')
        // }
      } else {
        this.$message.warning('供应商,物料名称,物料毛高,物料克重和物料门幅至少搜索三个');
      }
    },
    // 确认改价
    confirmPrice() {
      if (this.inputValue.length < 1) return this.$message.warning('请输入修改单价');
      post(mtrlAPI.editMtrlPriceByTerm, {
        supp_id: this.searchForm.supp_id,
        mtrl_name: this.searchForm.mtrl_name,
        mtrl_weight: this.searchForm.mtrl_weight,
        mtrl_thick: this.searchForm.mtrl_thick,
        mtrl_width: this.searchForm.mtrl_width,
        mtrl_prod_price: this.inputValue
      })
        .then(res => {
          let mg = res.data.msg;
          if (res.data.code === 0) {
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            this.splitFlag = false;
            this.initData();
          } else {
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //获取颜色大类option
    getOptnColor() {
      get(optnAPI.getAllContent, { perm_id: 10010 })
        .then(res => {
          if (res.data.code === 0) {
            this.optnColorList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    getStffType() {
      get(userAPI.getAllUsersV1)
        .then(res => {
          if (res.data.code === 0) {
            let stffType = res.data.data;
            let map = new Map();
            for (let item of stffType) {
              map.set(item.stff_name, item);
            }
            stffType = [...map.values()];
            this.stffType = stffType;
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(err => console.error(err));
    },
    // 获取成分
    getCompType() {
      get(optnAPI.getAllContent, { perm_id: 10008 })
        .then(res => {
          if (res.data.code === 0) {
            this.compType = res.data.data;
            let item = {};
            item.id = -1;
            item.param1 = '非印花';
            this.compType.push(item);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 查询方法
    getMtrlsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getMtrlsList();
    },
    // 状态查询
    getStatusVal(val) {
      this.searchForm.status = val;
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/mtrl_edit', { perm_id: permId, form_id: row.mtrl_id });
    },
    // //新增
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump(`/mtrl_add?perm_id=${permId}`);
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getMtrlsList();
    },
    // 物料单位转换
    formaUnitP(row) {
      return this.helper.haveFour(row.mtrl_prod_price) + '元/' + row.mtrl_unit;
    },
    // 图片类型
    formatPic(scope, type) {
      let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
      return this.helper.picUrl(scope.row.imge_url, type, updateTime);
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'error', message: '此单据已生效或者正在审批中,无法删除！' });
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    //物料信息list删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const ids = [];
          this.multiSelection.map(item => {
            ids.push(item.mtrl_id);
          });
          post(mtrlAPI.deleteMtrlByIds, { mtrl_id_list: ids })
            .then(res => {
              let mg = res.data.msg;
              if (res.data.code === 0) {
                let tp = 'success';
                this.$message({ message: mg, type: tp });
                if (ids.length === this.tableData.length) {
                  if (this.currentPage > 1) {
                    this.currentPage = this.currentPage - 1;
                    this.$refs.pubPagination.currentPage = this.currentPage;
                  }
                }
                this.initData();
              } else {
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.initData();
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              this.initData();
            });
        })
        .catch(() => {
          let mg = '取消删除！';
          let tp = 'info';
          this.$message({ message: mg, type: tp });
        });
    },
    //升序 降序
    sortChange(val) {
      if (val.order == 'ascending') {
        this.searchForm.mtrl_sort = 'asc';
        this.searchForm.mtrl_field = val.prop;
      } else if (val.order == 'descending') {
        this.searchForm.mtrl_sort = 'desc';
        this.searchForm.mtrl_field = val.prop;
      } else {
        this.searchForm.mtrl_sort = '';
        this.searchForm.mtrl_field = '';
      }
      this.searchForm.page_no = 1;
      this.getMtrlsList();

      // 升序 asc
      // 降序 desc
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  margin-top: 5px;
  width: 60px;
  height: 60px;
}
.vd_div {
  width: 40px;
  height: 20px;
  border: none;
  margin-right: 5px;
}
.vd_dis {
  display: flex;
}
.vd_popimg {
  width: 400px;
  height: 400px;
}
.vd_dis {
  display: flex;
  align-items: center;
}
.vd_right {
  text-align: right;
  padding-right: 10px;
}
</style>
