// import VueCookies from 'vue-cookies';
// import Vue from 'vue';
// Vue.use(VueCookies)
// import VueCookies from 'vue-cookies'; import Vue from 'vue'; Vue.use(VueCookies)
import axios from 'axios';

export default {
  //将json或Object转化成FormData
  toFormData(param) {
    let formData = new FormData();
    Object.keys(param).forEach(key => {
      formData.append(key, param[key]);
    });
    return formData;
  }, //过滤后只保留英语字母和数字
  keepEngNum(str) {
    return str.replace(/[^0-9a-zA-Z]/g, '');
  }, //过滤后只保留英语字母和数字和@
  keepEngNumA(str) {
    return str.replace(/[^0-9a-zA-Z@]/g, '');
  }, // 过滤只能输入英文
  keepEnglish(str) {
    return str.replace(/[^a-zA-Z]/g, '');
  },

  // 过滤后只保留数字和.
  keepTNum1(number) {
    return number.replace(/[^0-9.]/g, '');
  }, //过滤后只保留英语字母和数字加-
  keepEngNum1(str) {
    return str.replace(/[^0-9a-zA-Z-]/g, '');
  }, //过滤后只保留英语字母和数字加-加_@
  keepEngNum2(str) {
    return str.replace(/[^0-9a-zA-Z-_]/g, '');
  }, //过滤后只保留英语字母和数字加-加_
  keepEngNum2T(str) {
    return str.replace(/[^0-9a-zA-Z-_@]/g, '');
  }, // 限定输入正整数数字 ---用于公积金账号
  keepTNum(number) {
    return number.replace(/\D/g, '');
  }, // 限定输入正负整数数字
  keepTNumNega(number) {
    return number.replace(/[^0-9-]/g, '');
  },

  //保留四位小数
  keepEngNum3(str) {
    return str.replace(/[^0-9-_]/g, '');
  }, //过滤后只保留英语字母和数字-
  keepEngNum5(str) {
    console.log('进入方法了吗', str);
    return str.replace(/[^\u4E00-\u9FA50-9a-zA-Z-]/g, '');
  }, //保留小数
  keepEngNum4(str) {
    return str.replace(/[^0-9.]/g, '');
  }, // 限定输入数字和-
  keepNumberH(str) {
    return str.replace(/[^0-9-]/g, '');
  }, //时间戳转换成显示时间(到秒)
  toTime(time) {
    var date = new Date(parseInt(time) * 1000);
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return Y + M + D + h + m + s;
  }, //时间戳转换成显示时间(到秒) 13位
  toTime13(time) {
    var date = new Date(parseInt(time));
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return Y + M + D + h + m + s;
  }, //时间戳转换成显示时间(到日)
  toTimeDay(time) {
    var date = new Date(parseInt(time) * 1000);
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    return Y + M + D;
  }, // 国标时间转成时间戳 10 位
  toTimeVal(localTime) {
    return (localTime = Math.round(new Date().getTime() / 1000));
  },

  // 获取时间开始时间和结束时间戳
  getTime(timeVal) {
    if (timeVal && timeVal.length === 2) {
      timeVal.startTime = timeVal[0];
      timeVal.endTime = timeVal[1];
      timeVal.startTime = Number(new Date(timeVal.startTime).getTime() / 1000);
      timeVal.endTime = Number(new Date(timeVal.endTime).getTime() / 1000);
      return timeVal;
    } else {
      timeVal = [];
      return timeVal;
    }
  }, // 离职司龄
  toLeaveSen(entryDate, leaveDate) {
    entryDate = new Date(entryDate * 1000);
    leaveDate = new Date(leaveDate * 1000);
    if (leaveDate > entryDate) {
      let year = leaveDate.getFullYear() - entryDate.getFullYear();
      let month = leaveDate.getMonth() - entryDate.getMonth();
      if (month < 0) {
        year--;
        month = leaveDate.getMonth() + (12 - entryDate.getMonth());
      }
      if (year === 0 && month === 0) {
        return (entryDate = '未满一个月');
      } else if (year === 0 && month > 0) {
        return (entryDate = month + '个月');
      }
      return (entryDate = year + '年 ' + month + '个月');
    } else {
      return (entryDate = '离职日期小于现在时间!');
    }
  },

  // 计算司龄
  toSeniority(entryDate, leaveDate) {
    // if(leaveDate !==0){
    //  return entryDate = this.toLeaveSen(entryDate,leaveDate)
    // }
    let nowDate = new Date().getTime();
    entryDate = new Date(entryDate * 1000).getTime();
    if (leaveDate !== 0) {
      nowDate = new Date(leaveDate * 1000).getTime();
    }
    if (nowDate > entryDate) {
      let workDate = (nowDate - entryDate) / (60 * 60 * 24 * 30 * 1000);
      if (workDate >= 12) {
        // 一年以上
        if (workDate % 12 === 0) {
          return parseInt(workDate / 12) + '年';
        } else {
          return parseInt(workDate / 12) + '年' + parseInt(workDate % 12) + '个月';
        }
      } else if (parseInt(workDate) > 0) {
        return parseInt(workDate) + '个月';
      } else {
        return '未满一个月';
      }
    } else {
      return '日期填写不正确!';
    }
  },

  // 根据身份证号计算出生日期，年龄，性别
  mathUserInfo(stff_identity, num, birth, gender, age) {
    if (stff_identity.length === 18) {
      if (num === 1) {
        birth = stff_identity.substring(6, 10) + '-' + stff_identity.substring(10, 12) + '-' + stff_identity.substring(12, 14);
        return birth;
      }
      if (num === 2) {
        if (parseInt(stff_identity.substr(-2, 1)) % 2 === 1) {
          return (gender = '男');
        } else {
          return (gender = '女');
        }
      }
      if (num === 3) {
        var myDate = new Date();
        var month = myDate.getMonth() + 1;
        var day = myDate.getDate();
        age = myDate.getFullYear() - stff_identity.substring(6, 10) - 1;
        if (
          stff_identity.substring(10, 12) < month ||
          (stff_identity.substring(10, 12) === month && stff_identity.substring(12, 14) <= day)
        ) {
          age++;
        }
        return age;
      }
    } else {
      return (age = null), (birth = null), (gender = null);
    }
  },

  // 获取单个日期
  getNewDate(timeVal) {
    if (timeVal) {
      timeVal = Number(new Date(timeVal).getTime() / 1000);
      return timeVal;
    } else {
      timeVal = null;
      return timeVal;
    }
  }, // 时间转换为显示时间终极版
  toStringDate(rowVal) {
    if (rowVal) {
      return this.toTime(rowVal);
    }
  },
  toStringDay(rowVal) {
    if (rowVal) {
      return this.toTimeDay(rowVal);
    }
  }, // 数字字符串转成数组
  toArray(strArr) {
    strArr = strArr.split(',').map(Number);
    return strArr;
  }, // 转换合同状态
  getStatusName(status) {
    if (status === 0) {
      return { name: '草拟', type: 'info' };
    } else if (status === 1) {
      return { name: '在批', type: 'warning' };
    } else {
      return { name: '生效', type: 'success' };
    }
  }, //转换操作状态
  getInstAction(appr_inst_action) {
    if (appr_inst_action === 1) {
      return { name: '提交', type: 'primary' };
    } else if (appr_inst_action === 2) {
      return { name: '通过', type: 'success' };
    } else {
      return { name: '拒绝', type: 'danger' };
    }
  }, //转换下一节点状态
  getInstNextNode(appr_inst_next_node) {
    if (appr_inst_next_node === -1) {
      return { name: '草拟', type: 'info' };
    } else if (appr_inst_next_node === 0) {
      return { name: '生效', type: 'success' };
    } else {
      return { name: '在批', type: 'warning' };
    }
  }, // 拼接图片url地址
  picUrl(url, type, updateTime) {
    let header = '/file';
    if (url && url.indexOf('https') !== -1) return url;
    if (process.env.NODE_ENV === 'production') {
      header = '/dyjfile';
    }
    if (type === 'l') {
      return (url = header + url + '.jpg?' + updateTime);
    } else if (type === 'm') {
      return (url = header + url + '_m.jpg?' + updateTime);
    } else if (type === 's') {
      return (url = header + url + '_s.jpg?' + updateTime);
    }
  }, // 拼接视频地址
  pVideoUrl(url) {
    let updateTime = new Date().getTime();
    let header = '/file';
    if (process.env.NODE_ENV === 'production') {
      header = '/dyjfile';
    }
    return (url = header + url);
  },

  // 不用切图片
  picUrlNo(url) {
    if (url.indexOf('https') !== -1) return url;
    let updateTime = new Date().getTime();
    let header = '/file';
    if (process.env.NODE_ENV === 'production') {
      header = '/dyjfile';
    }
    return (url = header + url + '.jpg?' + updateTime);
  }, // 组合路径
  megPath(url) {
    if (url.indexOf('https') !== -1) return url;
    let header = '/file';
    if (process.env.NODE_ENV === 'production') {
      header = '/dyjfile';
    }
    if (url) {
      return (url = header + url);
    }
  }, // 下载文件zip
  downloadZip(url) {
    return new Promise((resolve, reject) => {
      let newUrl;
      let isHttps = url.indexOf('https') !== -1;
      switch (process.env.VUE_APP_ENV) {
        case 'production':
          newUrl = isHttps ? url : 'https://www.manenwork.com/' + url;
          break;
        case 'development':
          newUrl = isHttps ? url : url;
          break;
        case 'test':
          newUrl = isHttps ? url : 'https://www.petsprod.com/' + url;
          break;
      }
      // if (process.env.NODE_ENV === 'production') {
      //   // 线上
      //   // newUrl = "https://www.manenwork.com/" + url;
      //   // 测试
      //   newUrl = "https://www.petsprod.com/"+url
      // }
      axios
        .get(newUrl, { responseType: 'arraybuffer' })
        .then(data => {
          resolve(data.data);
        })
        .catch(error => {
          reject(error.toString());
        });
    });
  }, // 下载文件
  downloadItem(url, label) {
    let newUrl;
    if (url.indexOf('aliyun') !== -1) {
      newUrl = url;
    } else {
      switch (process.env.VUE_APP_ENV) {
        case 'production':
          newUrl = 'https://www.manenwork.com/' + url;
          break;
        case 'development':
          newUrl = url;
          break;
        case 'test':
          newUrl = 'https://www.petsprod.com/' + url;
          break;
      }
    }

    // if (process.env.NODE_ENV === 'production') {
    //   // 线上
    //   // newUrl = "https://www.manenwork.com/"+url
    //   // 测试
    //   newUrl = "https://www.petsprod.com/"+url
    // }
    axios
      .get(newUrl, { responseType: 'blob' })
      .then(response => {
        const blob = new Blob([response.data]);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = label;
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch(console.error);
  }, // 下载文件Excel
  downloadItemExcel(url, label) {
    let totalUrl;
    if (url.indexOf('aliyun') !== -1) {
      totalUrl = url;
    } else {
      switch (process.env.VUE_APP_ENV) {
        case 'production':
          totalUrl = 'https://www.manenwork.com/dyjfile' + url + '.xls';
          break;
        case 'development':
          totalUrl = '/file' + url + '.xls';
          break;
        case 'test':
          totalUrl = 'https://www.petsprod.com/dyjfile' + url + '.xls';
          break;
      }
    }
    let DownloadLink = document.createElement('a');
    DownloadLink.style = 'display: none'; // 创建一个隐藏的a标签
    DownloadLink.download = label;
    DownloadLink.href = totalUrl;
    document.body.appendChild(DownloadLink);
    DownloadLink.click(); // 触发a标签的click事件
    document.body.removeChild(DownloadLink);
  }, // 消息更新地址
  socketUrl(val) {
    let newUrl;
    switch (process.env.VUE_APP_ENV) {
      case 'production':
        newUrl = `wss://www.manenwork.com/dyj/api/ws/webSocket/${val}`;
        break;
      case 'development':
        newUrl = process.env.VUE_APP_SOCKET_URL + val;
        break;
      case 'test':
        newUrl = `wss://www.petsprod.com/dyj/api/ws/webSocket/${val}`;
        break;
    }
    // if (process.env.NODE_ENV === 'production') {
    //   // 线上
    //   // newUrl = `wss://www.manenwork.com/dyj/api/ws/webSocket/${val}`
    //   // 测试
    //   newUrl = `wss://www.petsprod.com/dyj/api/ws/webSocket/${val}`
    // }else{
    //   newUrl = `ws://192.168.1.236:8085/ws/webSocket/${val}`
    // }
    return newUrl;
  }, // 聘用类型
  reHireTap(type) {
    if (type === 1) {
      return '返聘';
    } else if (type === 2) {
      return '实习';
    } else if (type === 3) {
      return '劳务';
    } else if (type === 4) {
      return '正式';
    } else {
      return '无生效合同';
    }
  }, // 保留两位小数
  reservedDigits(value) {
    if (value || value === 0) {
      value = value.toString();
      let ind = value.toString().indexOf('.');
      if (ind < 0) {
        return value + '.00';
      } else {
        if (value.length - 1 - ind > 2) {
          return value.slice(0, ind + 5);
        } else if (value.length - 1 - ind < 2) {
          let index = 2 - (value.length - 1 - ind);
          for (let i = 0; i < index; i++) {
            value = value + '0';
          }
          return value;
        } else {
          return value;
        }
      }
    }
  }, // 保留两位小数
  reservedDigits4(value) {
    if (value || value === 0) {
      value = value.toString();
      let ind = value.toString().indexOf('.');
      if (ind < 0) {
        return value + '.0000';
      } else {
        if (value.length - 1 - ind > 4) {
          return value.slice(0, ind + 5);
        } else if (value.length - 1 - ind < 2) {
          let index = 4 - (value.length - 1 - ind);
          for (let i = 0; i < index; i++) {
            value = value + '0';
          }
          return value;
        } else {
          return value;
        }
      }
    }
  }, // 保留小数位数
  retain(num, posi = 0) {
    let num1 = 0;
    let fre = null;
    if (posi % 3 === 1) {
      fre = Math.pow(10, posi + 1);
    } else {
      fre = Math.pow(10, posi);
    }
    num1 = Math.floor(num * fre) / fre;
    if (isNaN(num1)) {
      return 0;
    } else {
      return num1;
    }
  }, // 保留小数位数且不能超出位数
  retain1(num, posi = 0, str) {
    let num1 = 0;
    let fre = null;
    if (posi % 3 === 1) {
      fre = Math.pow(10, posi + 1);
    } else {
      fre = Math.pow(10, posi);
    }
    num1 = Math.floor(num * fre) / fre;
    if (isNaN(num1)) {
      return 0;
    } else {
      if (num1 >= str) {
        return str - 0.01;
      } else {
        let value = num1;
        value = value.toString();
        if (value) {
          let ind = value.toString().indexOf('.');
          if (ind < 0) {
            return value + '.00';
          } else {
            if (value.length - 1 - ind > 2) {
              return value.substr(0, ind + 3);
            } else if (value.length - 1 - ind < 2) {
              let index = 2 - (value.length - 1 - ind);
              for (let i = 0; i < index; i++) {
                value = value + '0';
              }
              return value;
            } else {
              return value;
            }
          }
        }
      }
    }
  }, // 计算价格保留四位
  calcPrice(num, posi = 0, str) {
    let num1 = 0;
    let fre = null;
    if (posi % 3 === 1) {
      fre = Math.pow(10, posi + 1);
    } else {
      fre = Math.pow(10, posi);
    }
    num1 = (num * fre) / fre;
    if (isNaN(num1)) {
      return 0.0;
    } else {
      if (num1 >= str) {
        return str - 0.0001;
      } else {
        let value = num1;
        value = value.toString();
        if (value) {
          let ind = value.toString().indexOf('.');
          if (ind < 0) {
            return value + '.0000';
          } else {
            if (value.length - 1 - ind > 4) {
              return value.substr(0, ind + 5);
            } else if (value.length - 1 - ind < 4) {
              let index = 4 - (value.length - 1 - ind);
              for (let i = 0; i < index; i++) {
                value = value + '0';
              }
              return value;
            } else {
              return value;
            }
          }
        }
      }
    }
  }, // 计算位数保留四位
  haveFour(value) {
    if (value || value === 0) {
      value = value.toString();
      let ind = value.toString().indexOf('.');
      if (ind < 0) {
        return value + '.0000';
      } else {
        if (value.length - 1 - ind > 4) {
          return value.slice(0, ind + 5);
        } else if (value.length - 1 - ind < 4) {
          let index = 4 - (value.length - 1 - ind);
          for (let i = 0; i < index; i++) {
            value = value + '0';
          }
          return value;
        } else {
          return value;
        }
      }
    }
  },
  haveSix(value) {
    if (value || value === 0) {
      value = value.toString();
      let ind = value.toString().indexOf('.');
      if (ind < 0) {
        return value + '.000000';
      } else {
        if (value.length - 1 - ind > 6) {
          return value.slice(0, ind + 7);
        } else if (value.length - 1 - ind < 6) {
          let index = 6 - (value.length - 1 - ind);
          for (let i = 0; i < index; i++) {
            value = value + '0';
          }
          return value;
        } else {
          return value;
        }
      }
    }
  },
  // 控制状态删除
  isDelete(selection) {
    let statusList = selection.map(item => {
      return item.status;
    });
    const allStatus = statusList.reduce(function (sum, number) {
      return sum + number;
    }, 0);
    if (allStatus > 0) {
      return (selection = false);
    } else {
      return (selection = true);
    }
  },
  modePrepend(val) {
    if (process.env.NODE_ENV === 'production') {
      return val;
    } else {
      return val;
    }
  },

  // 编辑删除保存
  deleteSaveBody(formMain, formMainList, formCopyList, no) {
    let delete_time = this.toTimeVal();
    let formLi = [];
    let form = {};
    form = Object.assign(form, formMain);
    formLi = Object.assign(formLi, formCopyList);
    for (let i = formLi.length - 1; i >= 0; i--) {
      if (formMainList.indexOf(formLi[i]) !== -1) {
        formLi.splice(i, 1);
      }
    }
    if (no === 1) {
      for (let i = 0; i < formLi.length; i++) {
        formLi[i].destroy_flag = 1;
      }
    } else if (no === 2) {
      for (let i = 0; i < formLi.length; i++) {
        formLi[i].delete_time = delete_time;
      }
    }
    return (formMainList = formLi);
  },

  // 深拷贝
  deepCopy(oldObj) {
    let newObj = oldObj instanceof Array ? [] : {};
    for (let key in oldObj) {
      // oldobj是数组 key 为索引，是对象为属性值
      // 判断值是否是原始类型
      if (typeof oldObj[key] !== 'object') {
        newObj[key] = oldObj[key];
      } else {
        newObj[key] = this.deepCopy(oldObj[key]);
      }
    }
    return newObj;
  },

  // list显示录入人组合字符串
  personCombina(scope) {
    let stffName = scope.stff_name;
    let deptName = scope.dept_name;
    let deptTeamName = scope.dept_team_name;
    if (stffName === null) {
      stffName = '';
    }
    if (deptName === null) {
      deptName = '';
    }
    if (deptTeamName === null) {
      deptTeamName = '';
    }
    let combination = stffName + '(' + deptName + ' ' + deptTeamName + ')';
    return combination;
  }, // 只能输入电话
  purephone(str) {
    return str.replace(/[^0-9-]/g, '');
  }, // 只能输入数字 逗号 乘号
  numNS(str) {
    return str.replace(/[^0-9,*/.]/g, '');
  }, // 只能输入汉语
  keepChin(str) {
    return str.replace(/[^\u4e00-\u9fa5]/g, '');
  }, // 过滤只能输入英文
  keepEnglish1(str) {
    return str.replace(/[^a-zA-Z ]/g, '');
  }, // 不能输入汉语
  NotkeepChin(str) {
    return str.replace(/[\u4E00-\u9FA5]/g, '');
  }, // 返回permid
  retPermId(val) {
    switch (val) {
      case 'mtrl':
        return 110;
      case 'sour':
        return 113;
      case 'smpl':
        return 112;
      case 'mmpl':
        return 130;
    }
  },
  getFloat(number, n) {
    n = n ? parseInt(n) : 0;
    let number1 = (number * Math.pow(10, n)) / Math.pow(10, n);
    // number = Number(number).toFixed(n); //补足位数
    return number1;
  }, // 判断obj是否为一个整数
  isInteger(obj) {
    return Math.floor(obj) === obj;
  },
  toInteger(floatNum) {
    let ret = { times: 1, num: 0 };
    if (this.isInteger(floatNum)) {
      ret.num = floatNum;
      return ret;
    }
    let strfi = floatNum + '';
    let dotPos = strfi.indexOf('.');
    let len = strfi.substr(dotPos + 1).length;
    let times = Math.pow(10, len);
    let intNum = parseInt(floatNum * times + 0.5, 10);
    ret.times = times;
    ret.num = intNum;
    return ret;
  },
  operation(a, b, op) {
    let o1 = this.toInteger(a);
    let o2 = this.toInteger(b);
    let n1 = o1.num;
    let n2 = o2.num;
    let t1 = o1.times;
    let t2 = o2.times;
    let max = t1 > t2 ? t1 : t2;
    let result = null;
    switch (op) {
      case 'add':
        if (t1 === t2) {
          // 两个小数位数相同
          result = n1 + n2;
        } else if (t1 > t2) {
          // o1 小数位 大于 o2
          result = n1 + n2 * (t1 / t2);
        } else {
          // o1 小数位 小于 o2
          result = n1 * (t2 / t1) + n2;
        }
        return result / max;
      case 'subtract':
        if (t1 === t2) {
          result = n1 - n2;
        } else if (t1 > t2) {
          result = n1 - n2 * (t1 / t2);
        } else {
          result = n1 * (t2 / t1) - n2;
        }
        return result / max;
      case 'multiply':
        result = (n1 * n2) / (t1 * t2);
        return result;
      case 'divide':
        result = (n1 / n2) * (t2 / t1);
        return result;
    }
  }
};
