<template>
  <div class="wrapper-box">
    <el-card>
      <div slot="header" class="vd_clearfix">
        <span class="Recently">
          <span style="font-size: 15px">已接收委托单:</span>
          <span :class="{ vg_rotate: rotateFlag, 'el-icon-refresh': true }" @click="loadingChange" />
          <i>(最近20条)</i>
        </span>
      </div>
      <div class="card_body">
        <div v-if="receiveList.length > 0">
          <el-card shadow="hover" v-for="(item, index) in receiveList" :key="index" class="vg_mt_8"
                   v-loading="loading">
            <div>
              <el-form size="mini" :label-position="labelPosition">
                <el-row>
                  <el-col :md="5">
                    <el-form-item label="委托单号：" prop="requ_no_smpl_no">
                      <span> {{ item.requ_no_smpl_no }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :md="5">
                    <el-form-item label="委托人：" prop="requ_stff_name">
                      <span> {{ item.requ_stff_name }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :md="5">
                    <el-form-item label="客户名称：" prop="cust_abbr">
                      <span> {{ item.cust_abbr }}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :md="5">
                    <el-form-item label="打样台面：" prop="requ_receive">
                      <span> {{ item.requ_receive }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :md="5">
                    <el-form-item label="下单时间：" prop="create_time">
                      {{ helper.toTime(item.create_time) }}
                    </el-form-item>
                  </el-col>
                  <el-col :md="5">
                    <el-form-item label="接收时间：" prop="receive_time">
                      {{ helper.toTime13(item.receive_time) }}
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :md="5">
                    <el-form-item label="DYJ接收人：" prop="stff_name">
                      <span> {{ item.stff_name }}</span>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </el-card>
        </div>
        <div class="el-icon-warning-outline notVal" v-else>暂无</div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { get } from '@api/request';
import { debounce } from 'lodash';
import { requAPI } from '@api/modules/requ';
import helper from '../../../assets/js/helper';

export default {
  name: 'boardLeftRequReceive',
  computed: {
    helper() {
      return helper;
    }
  },
  data() {
    return {
      labelPosition: 'left',
      receiveList: [],
      loading: true,
      rotateFlag: false
    };
  },
  created() {
  },
  mounted() {
    this.initData();
  },
  watch: {
    $route({ name }, from) {
      if (name === 'dashboard') {
        this.getboardLeftRequReceive();
      }
    },
    '$store.state.reGetDashBoardData'(newValue, oldValue) {
      this.getboardLeftRequReceive();
    }
  },
  methods: {
    initData() {
      this.getboardLeftRequReceive();
      setTimeout(() => {
        this.rotateFlag = false;
      }, 1100);
    },
    // 获取待审批记录 节流
    getboardLeftRequReceive: debounce(function() {
      let dept_id = this.$cookies.get('userInfo').dept_id;
      get(requAPI.getRequReceiveList, { dept_id: dept_id,page_size:20 })
        .then(res => {
          if (res.data.code === 0) {
            this.$data.loading = false;
            this.$data.receiveList = res.data.data.list;
          } else if (res.data.code === 3) {
            this.deleteCookie();
            this.$router.push('/login');
          } else {
            this.$message({ message: res.data.msg, type: 'error' });
          }
        })
        .catch(res => {
          this.$message({ message: res.data.msg, type: 'error' });
        });
    }, 500),
    // 刷新
    loadingChange() {
      this.loading = true;
      this.rotateFlag = true;
      this.initData();
    }
  }
};
</script>

<style scoped lang="scss">
.wrapper-box ::v-deep .el-card__header {
  padding: 8px 20px;
}

.vd_clearfix {
  display: flex;
  justify-content: space-between;
  height: 40px;

  span {
    display: flex;
    align-items: center;
  }
}

.card_body {
  padding-right: 10px;
  overflow: auto;
}

@media screen and (min-width: 993px) {
  .card_body {
    height: 350px;
  }
}

@media screen and (max-width: 992px) {
  .card_body {
    height: 700px;
  }
}

.card_body {
  position: relative;
}

.notVal {
  font-size: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #ccc;
}

.Recently {
  i {
    font-size: 10px;
    font-style: inherit;
    color: #ccc;
    margin-top: 5px;
  }

  span {
    margin: 0 10px;
    margin-top: 3px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;

    &:hover {
      color: blue;
    }
  }
}

::v-deep .el-card__body {
  padding-bottom: 0;
  padding-top: 0;
}

::v-deep .card_body {
  height: 390px;
}
</style>
