<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <editHeader :isShow="isShow" :btn="btn" :strForm="cpttForm" @openEdit="openEdit" @closeEdit="closeEdit" @submit="submit('cpttForm')" />
    <el-form ref="cpttForm" :model="cpttForm" :rules="rules" :disabled="isShow" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="公司编号" prop="cptt_no">
            <el-input
              v-model="cpttForm.cptt_no"
              maxlength="10"
              show-word-limit
              :disabled="isDisabled"
              placeholder="请填写公司编号（仅支持数字和英文）"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司电话" prop="cptt_phone">
            <el-input
              v-model="cpttForm.cptt_phone"
              @input="cpttForm.cptt_phone = helper.keepEngNum3(cpttForm.cptt_phone)"
              maxlength="30"
              show-word-limit
              placeholder="请填写公司电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司传真" prop="cptt_fax">
            <el-input
              v-model="cpttForm.cptt_fax"
              @input="cpttForm.cptt_fax = helper.keepEngNum3(cpttForm.cptt_fax)"
              maxlength="30"
              show-word-limit
              placeholder="请填写公司传真"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司税号" prop="cptt_tax_no">
            <el-input
              v-model="cpttForm.cptt_tax_no"
              @input="cpttForm.cptt_tax_no = helper.keepEngNum3(cpttForm.cptt_tax_no)"
              maxlength="30"
              show-word-limit
              placeholder="请填写公司税号"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8" :offset="4">
          <el-form-item label="公司名称（中）" prop="cptt_name">
            <el-input
              v-model="cpttForm.cptt_name"
              @input="cpttForm.cptt_name = helper.keepChin(cpttForm.cptt_name)"
              maxlength="50"
              show-word-limit
              placeholder="请填写公司名称（中文）"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司名称（英）" prop="cptt_name_en">
            <el-input
              v-model="cpttForm.cptt_name_en"
              @input="cpttForm.cptt_name_en = helper.keepEnglish1(cpttForm.cptt_name_en)"
              maxlength="100"
              show-word-limit
              placeholder="请填写公司名称（英文）"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司地址（中）" prop="cptt_address">
            <el-input
              v-model="cpttForm.cptt_address"
              @input="cpttForm.cptt_address = keepChin(cpttForm.cptt_address)"
              placeholder="请填写公司地址（中文）"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司地址（英）">
            <el-input
              v-model="cpttForm.cptt_address_en"
              @input="cpttForm.cptt_address_en = keepEnglish1(cpttForm.cptt_address_en)"
              placeholder="请填写公司地址（英文）"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <div class="vd_button">
        <el-button type="primary" plain size="small" @click="addRow()">添加</el-button>
        <el-button type="danger" plain size="small" @click="delBank()">删除</el-button>
      </div>

      <el-table ref="bank" :data="cpttForm.cptt_bank_list" border class="vg_mt_16" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="48" align="center"></el-table-column>
        <el-table-column label="序号" width="48" align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span class="vg_deep_red">银行代码</span>
          </template>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'cptt_bank_list.' + scope.$index + '.cptt_bank_code'" :rules="[{ required: true }]">
              <el-input
                v-model="cpttForm.cptt_bank_list[scope.$index].cptt_bank_code"
                maxlength="50"
                show-word-limit
                placeholder="请填写银行代码（必填）"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span class="vg_deep_red">银行名称</span>
          </template>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'cptt_bank_list.' + scope.$index + '.cptt_bank_name'" :rules="[{ required: true }]">
              <el-input
                v-model="cpttForm.cptt_bank_list[scope.$index].cptt_bank_name"
                maxlength="100"
                show-word-limit
                placeholder="请填写银行名称（必填）"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="账号信息" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-form-item label-width="0">
              <el-input
                type="textarea"
                :rows="1"
                v-model="cpttForm.cptt_bank_list[scope.$index].cptt_bank_account"
                placeholder="请填写账号信息"
              ></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column align="center">
          <template slot-scope="scope">
            <el-form-item label-width="0">
              <el-radio v-model="defaultRadio" :label="cpttForm.cptt_bank_list[scope.$index].k">默认</el-radio>
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
      <el-row class="vg_mt_16">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" v-if="isShowInputer"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get } from '@api/request';
import { post } from '@api/request';
import { cpttAPI } from '@api/modules/comptitle';
import inputUser from '@/views/component/inputUser';
import editHeader from '@/views/component/editHeader';
import { getDateNoTime } from '@assets/js/dateUtils';
export default {
  name: 'CpttEditMain',
  components: {
    inputUser,
    editHeader
  },
  data() {
    return {
      cpttForm: {
        // key:0,
        status: null,
        cptt_id: '',
        cptt_no: '',
        cptt_name: '',
        cptt_name_en: '',
        cptt_address: '',
        cptt_address_en: '',
        cptt_phone: '',
        cptt_fax: '',
        cptt_tax_no: '',
        cptt_bank_list: []
      },
      stffForm: {},
      cptt_bank_copy: [],
      key: 0,
      isShowInputer: false,
      newFlag: false,
      isShow: true,
      isDisabled: true,
      btn: {},
      defaultRadio: 0,
      selectionsList: [],
      rules: {
        cptt_no: [{ required: true, trigger: 'blur', message: ' ' }],
        cptt_name: [{ required: true, trigger: 'blur', message: ' ' }],
        cptt_name_en: [{ required: true, trigger: 'blur', message: ' ' }],
        cptt_address: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      loadingFlag: true
    };
  },
  watch: {
    defaultRadio: {
      immediate: true,
      handler: function (newVal) {
        this.cpttForm.cptt_bank_list.forEach(item => {
          if (item.k === newVal) {
            item.cptt_bank_default_flag = 1;
          } else {
            item.cptt_bank_default_flag = 0;
          }
        });
      }
    }
  },
  created() {
    this.initData();
  },
  methods: {
    //启用编辑
    openEdit() {
      this.isShow = false;
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    initData() {
      if (this.$route.query.form_id) {
        this.loadingFlag = true;
        this.getCpttInfo();
      }
    },
    //提交表单
    submit(formName) {
      this.$confirm('确定提交？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      let deleteTime = this.helper.toTimeVal();
      this.cpttForm.cptt_id = this.$route.query.form_id;
      this.cptt_bank_copy = this.cptt_bank_copy.filter(item =>
        this.cpttForm.cptt_bank_list.every(item1 => item.cptt_bank_id !== item1.cptt_bank_id)
      );
      for (let i = 0; i < this.cptt_bank_copy.length; i++) {
        this.cptt_bank_copy[i].delete_time = deleteTime;
      }
      this.cpttForm.cptt_bank_list = this.cpttForm.cptt_bank_list.concat(this.cptt_bank_copy);
      post(cpttAPI.editCptt, this.cpttForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 只能输入汉语
    keepChin(str) {
      return str.replace(/[^0-9\u4e00-\u9fa5 ]/g, '');
    },
    // 过滤只能输入英文
    keepEnglish1(str) {
      return str.replace(/[^0-9a-zA-Z ]/g, '');
    },
    //添加
    addRow() {
      let default_flag = 0;
      if (this.cpttForm.key === 0) {
        default_flag = 1;
      }
      let item = {
        k: this.key,
        cptt_bank_code: '',
        cptt_bank_name: '',
        cptt_bank_account: '',
        cptt_bank_default_flag: default_flag
      };
      this.key = this.key + 1;
      this.cpttForm.cptt_bank_list.push(item);
      this.selectionsList = [];
    },
    //
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
    },
    //多选删除
    delBank() {
      if (this.selectionsList.length == 0) {
        return this.$message({ type: 'warning', message: '请至少选择一条数据' });
      }
      let temp = [];
      for (let i = 0; i < this.selectionsList.length; i++) {
        let ind = null;
        ind = this.cpttForm.cptt_bank_list.indexOf(this.selectionsList[i]);
        temp.push(ind);
      }
      temp = temp.sort((a, b) => {
        return b - a;
      });
      for (let j of temp) {
        this.cpttForm.cptt_bank_list.splice(j, 1);
      }
    },
    //获取表单信息
    getCpttInfo() {
      this.cpttForm.cptt_id = this.$route.query.form_id;
      get(cpttAPI.getCpttById, { cptt_id: this.cpttForm.cptt_id })
        .then(res => {
          if (res.data.code === 0) {
            this.cpttForm = res.data.data.form;
            // let that = this
            // setTimeout(function() {
            //   that.stffForm.staffName = that.cpttForm.stff_name;
            //   that.stffForm.deptName = that.cpttForm.dept_name;
            //   that.stffForm.deptTeamName = that.cpttForm.dept_team_name;
            // }, 100);
            this.stffForm.stff_name = this.cpttForm.stff_name;
            this.stffForm.dept_name = this.cpttForm.dept_name;
            this.stffForm.dept_team_name = this.cpttForm.dept_team_name;
            this.stffForm.create_time = this.cpttForm.create_time;
            this.cptt_bank_copy = JSON.parse(JSON.stringify(this.cpttForm.cptt_bank_list));
            this.cpttForm.cptt_bank_list.forEach(item => {
              item.k = this.key;
              if (item.cptt_bank_default_flag === 1) {
                this.defaultRadio = item.k;
              }
              this.key++;
            });
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadingFlag = false;
              this.isShowInputer = true;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_button {
  margin-top: 32px;
  border-top: 1px dashed $color-secondary-text;
  padding-top: 16px;
}
.vd_button_group {
  padding-bottom: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid $color-secondary-text;
}
.vg_wrapper ::v-deep.el-form-item {
  margin: 0;
  padding: 0;
}
.vg_wrapper ::v-deep .el-table td,
::v-deep.el-table th {
  padding: 0;
}

.vg_wrapper ::v-deep .el-table .cell {
  padding: 0;
}
::v-deep.is-disabled .el-input__inner {
  cursor: default;
  color: $color-conventional-text;
}
</style>
