<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <editHeader
        :isShow="isShow"
        :btn="btn"
        :strForm="suppForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('suppForm')"
      />
      <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
    </div>
    <el-form ref="suppForm" :model="suppForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row>
        <el-col :md="8">
          <el-form-item label="供应商编号" prop="supp_no">
            <!-- <el-input v-model="suppForm.supp_no" @input ="suppForm.supp_no = helper.keepEngNum1(suppForm.supp_no)" placeholder="请填写账套编号（仅支持数字和英文和-）" minlength="30"/> -->
            <el-input
              maxlength="10"
              v-model="suppForm.supp_no"
              @input="suppForm.supp_no = helper.keepEngNum2(suppForm.supp_no)"
              placeholder="保存后显示"
              show-word-limit
              disabled
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="供应商简称" prop="supp_abbr">
            <el-input v-model="suppForm.supp_abbr" maxlength="9" show-word-limit placeholder="请填写供应商简称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="供应商全称" prop="supp_name">
            <el-input v-model="suppForm.supp_name" maxlength="50" show-word-limit placeholder="请填写供应商全称"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8">
          <el-form-item label="供应商类型" prop="supp_type">
            <el-select v-model="suppForm.supp_type" placeholder="请选择供应商类型" size="mini" clearable show-word-limit>
              <el-option v-for="item in suppType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="联系人姓名" prop="liaison_name">
            <el-input v-model="suppForm.liaison_name" maxlength="30" show-word-limit placeholder="请填写联系人"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="联系电话" prop="liaison_tel">
            <el-input
              v-model="suppForm.liaison_tel"
              @input="suppForm.liaison_tel = helper.keepNumberH(suppForm.liaison_tel)"
              maxlength="20"
              show-word-limit
              placeholder="请填写联系电话"
            ></el-input>
          </el-form-item>
        </el-col>
<!--        <el-col :md="8">-->
<!--          <el-form-item label="主营类目" prop="supp_maincate">-->
<!--            <el-select filterable v-model="suppForm.supp_maincate" placeholder="请选择主营类目" size="mini" clearable show-word-limit>-->
<!--              <el-option v-for="item in mainBusinessList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
      </el-row>
      <el-row>
        <el-col :md="8">
          <el-form-item label="供应商地址" prop="supp_addr">
            <el-input
              type="textarea"
              :rows="3"
              v-model="suppForm.supp_addr"
              maxlength="100"
              show-word-limit
              placeholder="请填写供应商地址"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="suppForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { suppAPI } from '@api/modules/supp';
import { optnAPI } from '@api/modules/optn';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';

export default {
  name: 'suppEditMain',
  components: {
    editHeader,
    inputUser
  },
  data() {
    return {
      rules: {
        supp_name: [{ required: true, trigger: 'blur', message: ' ' }],
        supp_type: [{ required: true, trigger: 'change', message: ' ' }]
      },
      suppForm: {
        supp_no: null,
        supp_name: null,
        supp_type: null,
        supp_maincate: null
      },
      isShow: true,
      btn: {},
      stffForm: {},
      suppType: [
        { id: 1, label: '加工厂' },
        { id: 2, label: '面料厂' },
        { id: 3, label: '配套厂' },
        { id: 4, label: '辅料厂' }
      ],
      loadingFlag: true,
      mainBusinessList: []
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.loadingFlag = true;
      this.getSuppInfo();
      this.getCompType();
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      post(suppAPI.editSupp, this.suppForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.initData();
          } else if (res.data.code === 7) {
            this.$message({
              type: 'error',
              message: '供应商编号重复'
            });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('suppForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 获取成分
    getCompType() {
      get(optnAPI.getOptnByPermId, { perm_id: 10012 })
        .then(res => {
          if (res.data.code === 0) {
            this.mainBusinessList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 获取表单信息
    getSuppInfo() {
      this.suppForm.supp_id = this.$route.query.form_id;
      get(suppAPI.getSuppById, { supp_id: this.suppForm.supp_id })
        .then(res => {
          if (res.data.code === 0) {
            this.suppForm = res.data.data.form;
            this.btn = res.data.data.btn;
            this.stffForm.stff_name = this.suppForm.stff_name;
            this.stffForm.dept_name = this.suppForm.dept_name;
            this.stffForm.dept_team_name = this.suppForm.dept_team_name;
            this.stffForm.user_id = this.suppForm.user_id;
            this.stffForm.dept_id = this.suppForm.dept_id;
            this.stffForm.stff_id = this.suppForm.stff_id;
            this.stffForm.create_time = this.suppForm.create_time;
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_mrt1 {
  margin-top: 1px;
}
.vd_edhea {
  position: relative;
}
.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
</style>
