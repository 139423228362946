<template>
  <div class="vg_wrapper">
    <div class="vd_search_group">
      <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getSmplsNow()"
               label-width="120px">
        <el-row>
          <el-col :md="8">
            <el-form-item label="样品编号:">
              <el-input size="small" v-model.trim="searchForm.smpl_no" clearable
                        placeholder="请填写样品编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="样品名称:">
              <el-input size="small" v-model.trim="searchForm.smpl_name" clearable
                        placeholder="请填写样品名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="委托单号:">
              <el-input size="small" v-model.trim="searchForm.requ_no" clearable
                        placeholder="请填写委托单号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="8">
            <el-form-item label="开版师:">
              <el-input size="small" v-model.trim="searchForm.smpl_stff_name" clearable
                        placeholder="请填写开版师"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-button size="small" type="primary" icon="el-icon-search" @click="getSmplsNow()" style="margin: 10px 10px 0 45px">查询
            </el-button>
            <el-button size="small" type="info" icon="el-icon-refresh" @click="getSmplsNowO()" style="margin: 10px 10px 0 10px">刷新
            </el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-row class="vg_mt_16">
      <el-col :md="24">
        <el-checkbox-group v-model="checkList" @change="checkboxChange">
          <el-checkbox label="原材料"></el-checkbox>
          <el-checkbox label="辅助材料"></el-checkbox>
          <el-checkbox label="人工工资"></el-checkbox>
          <el-checkbox label="配件信息"></el-checkbox>
        </el-checkbox-group>
      </el-col>
    </el-row>
    <el-row class="vd_mrt">
      <el-col :md="24">
        <el-table ref="multiTable" :data="tableData" border v-loading="loading" class="vg_cursor"
                  @row-dblclick="confirmIn">
          <el-table-column label="委托单号" prop="requ_no" />
          <el-table-column label="样品编号" prop="smpl_no" />
          <el-table-column label="样品名称" prop="smpl_name" />
          <el-table-column label="更改号" prop="smpl_suffix">
            <template slot-scope="scope">
              <div v-if="scope.row.smpl_suffix">
                {{ scope.row.smpl_suffix }}
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
          <el-table-column label="版本号" prop="version_number">
            <template slot-scope="scope">
              <div v-if="scope.row.version_number">
                {{ scope.row.version_number }}
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
          <el-table-column prop="smpl_type" label="样品类型" align="center">
            <template v-slot="scope">
              <span>{{ scope.row.smpl_type === 1 ? '新款' : scope.row.smpl_type === 2 ? '老款' : '修改款' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="打样接收人" prop="smpl_receive">
            <template slot-scope="scope">
              <div v-if="scope.row.smpl_receive">
                {{ scope.row.smpl_receive }}
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
          <el-table-column label="开板师" prop="smpl_stff_name">
            <template slot-scope="scope">
              <div v-if="scope.row.smpl_stff_name">
                {{ scope.row.smpl_stff_name }}
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <pubPagination ref="pubPagination" :totalPage="totalPage" @changePageSearch="changePageSearch"></pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get } from '@api/request';
import pubPagination from '@/components/common/pubPagination';
import { qutaAPI } from '@api/modules/quta';

export default {
  name: 'SmplEditBom',
  components: {
    pubPagination
  },
  props: {
    qutaForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        smpl_name: '',
        smpl_no: '',
        requ_no: '',
        smpl_stff_name: '',
        belo_dept_id: ''
      },
      totalPage: 0,
      loading: true,
      multiSelection: [],
      currentPage: 1,
      checkList: ['原材料', '辅助材料', '人工工资', '配件信息'],
      smplForm: {
        smpl: 1,
        smpl_bom: 1,
        smpl_pl: 1,
        smpl_cut: 1,
        smpl_wip: 1,
        smpl_part: 1
      }
    };
  },
  created() {
    this.searchForm.smpl_no = this.qutaForm.smpl_no;
    this.initData();
  },
  methods: {
    initData() {
      this.getSmplsList();
    },
    // 获取
    getSmplsList() {
      this.loading = true;
      let param = {};
      param.quta_id = this.qutaForm.quta_id;
      param.smpl_no = this.searchForm.smpl_no;
      param.smpl_name = this.searchForm.smpl_name;
      param.requ_no = this.searchForm.requ_no;
      param.smpl_stff_name = this.searchForm.smpl_stff_name;
      param.belo_dept_id = this.qutaForm.belo_dept_id;
      param.page_no = this.currentPage;
      get(qutaAPI.getOtherQouteaList, param)
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.loading = false;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    checkboxChange(e) {
      // this.checkList.forEach(item => {
      //   if (item === '加工面料') {
      //     if (e.indexOf('BOM清单') === -1) {
      //       this.checkList.push('BOM清单');
      //     }
      //   }
      //   if (item === '裁片信息') {
      //     if (e.indexOf('BOM清单') === -1) {
      //       this.checkList.push('BOM清单');
      //     }
      //     if (e.indexOf('加工面料') === -1) {
      //       this.checkList.push('加工面料');
      //     }
      //   }
      //   // if (item === '在制品信息') {
      //   //   if (e.indexOf('BOM清单') === -1) {
      //   //     this.checkList.push('BOM清单');
      //   //   }
      //   //   if (e.indexOf('加工面料') === -1) {
      //   //     this.checkList.push('加工面料');
      //   //   }
      //   //   if (e.indexOf('裁片信息') === -1) {
      //   //     this.checkList.push('裁片信息');
      //   //   }
      //   // }
      // });
    },
    // 查询方法
    getSmplsNow() {
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getSmplsList();
    },
    // 刷新
    getSmplsNowO() {
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.searchForm = {
        smpl_name: '',
        smpl_no: '',
        requ_no: '',
        smpl_stff_name: ''
      };
      this.getSmplsList();
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getSmplsList();
    },
    // 确认选择
    confirmIn(row) {
      this.$emit('childSmpl', row,this.checkList);
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}

.vd_mrt {
  margin-top: 20px;
}

.vd_mar10 {
  margin: 10px 0;
}
</style>
