import topic from '../topic';
export const qutaAPI = {
  getQutas: topic.qutaTopic + '/get_qutas',
  getQutaById: topic.qutaTopic + '/get_quta_by_id',
  addQuta: topic.qutaTopic + '/add_quta',
  editQuta: topic.qutaTopic + '/edit_quta',
  deleteQutaByIds: topic.qutaTopic + '/delete_quta_by_ids',
  get_quta_pdf_by_id: topic.pdfTopic + '/get_quta_pdf_by_id',
  createRqta: topic.qutaTopic + '/add_rqta',
  getOtherQouteaList: topic.qutaTopic + '/getOtherQouteaList'
};
